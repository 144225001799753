import { useLoginValidation } from '@utility';


export const getUserBasicDetails = () => {
  return (JSON.parse(window.localStorage.getItem('userData')) || {}).user;
};

export const setUserBasicDetails = (data) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  userData.user = data;
  window.localStorage.setItem('userData', JSON.stringify(userData));
};

export const getToken = () => {
  return (JSON.parse(window.localStorage.getItem('userData')) || {}).token;
};

export const isUserLogin = () => {
  return (JSON.parse(window.localStorage.getItem('userData')) || {}).token;
};

export const getUserId = () => {
  const userData = (JSON.parse(window.localStorage.getItem('userData')) || {});
  let userId = null;
  if (userData && userData.user) {
    userId = userData.user.id;
  }
  return userId;
};

export const getUserRoleId = () => {
  const userData = (JSON.parse(window.localStorage.getItem('userData')) || {});
  let roleId = null;
  if (userData && userData.user) {
    roleId = userData.user.roleId;
  }
  return roleId;
};
export const hasPermission = (keys) => {
  const { loginValidationData } = useLoginValidation.loginValidation();
  if (!loginValidationData || !loginValidationData.permissions) {
    return false;
  }
  const userPermissions = loginValidationData.permissions.map(permission => permission.resourceKey);
  const keysArray = Array.isArray(keys) ? keys : [keys];
  return keysArray.some(key => userPermissions.includes(key));
};
export default {
  getUserBasicDetails,
  setUserBasicDetails,
  getToken,
  isUserLogin,
  getUserRoleId,
  getUserId,
  hasPermission
};
