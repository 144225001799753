export const ADD_USER_REQUESTED = 'users/USERS_REQUESTED';
export const ADD_USER_SUCCESS = 'users/USERS_SUCCESS';
export const ADD_USER_FAILURE = 'users/USERS_FAILURE';

export const UPDATE_USER_REQUESTED = 'users/USERS_REQUESTED';
export const UPDATE_USER_SUCCESS = 'users/USERS_SUCCESS';
export const UPDATE_USER_FAILURE = 'users/USERS_FAILURE';

export const GET_USER_BYID_REQUESTED = 'users/GET_COMPANY_BYID_REQUESTED';
export const GET_USER_BYID_SUCCESS = 'users/GET_COMPANY_BYID_SUCCESS';
export const GET_USER_BYID_FAILURE = 'users/GET_COMPANY_BYID_FAILURE';

export const GET_USER_REQUESTED = 'users/GET_STORES_REQUESTED';
export const GET_USER_SUCCESS = 'users/GET_STORES_SUCCESS';
export const GET_USER_FAILURE = 'users/GET_STORES_FAILURE';

export const GET_STORES_BY_COMPANY_ID_REQUESTED = 'users/GET_STORES_BY_COMPANY_ID_REQUESTED';
export const GET_STORES_BY_COMPANY_ID_SUCCESS = 'users/GET_STORES_BY_COMPANY_ID_SUCCESS';
export const GET_STORES_BY_COMPANY_ID_FAILURE = 'users/GET_STORES_BY_COMPANY_ID_FAILURE';

export const SEND_REVOKE_APIKEY_REQUESTED = 'users/SEND_REVOKE_APIKEY_REQUESTED';
export const SEND_REVOKE_APIKEY_SUCCESS = 'users/SEND_REVOKE_APIKEY_SUCCESS';
export const SEND_REVOKE_APIKEY_FAILURE = 'users/SEND_REVOKE_APIKEY_FAILURE';

export const GET_USER_ROLE_LIST_REQUESTED = 'users/GET_USER_ROLE_LIST_REQUESTED';
export const GET_USER_ROLE_LIST_SUCCESS = 'users/GET_USER_ROLE_LIST_SUCCESS';
export const GET_USER_ROLE_LIST_FAILURE = 'users/GET_USER_ROLE_LIST_FAILURE';

export const GET_ROLE_TYPES_REQUESTED = 'users/GET_ROLE_TYPES_REQUESTED ';
export const GET_ROLE_TYPES_SUCCESS = 'users/GET_ROLE_TYPES_SUCCESS';
export const GET_ROLE_TYPES_FAILURE = 'users/GET_ROLE_TYPES_FAILURE';

export const DELETE_USER_REQUESTED = 'users/DELETE_USER_REQUESTED';
export const DELETE_USER_SUCCESS = 'users/DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'users/DELETE_USER_FAILURE';

export const UNLOCK_USER_REQUESTED = 'users/UNLOCK_USER_REQUESTED';
export const UNLOCK_USER_SUCCESS = 'users/UNLOCK_USER_SUCCESS';
export const UNLOCK_USER_FAILURE = 'users/UNLOCK_USER_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  getUserByID: null,
  userList: [],
  storeByCompanyId: [],
  roleTypes: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ADD_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case ADD_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case UPDATE_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case GET_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        userList: action.result,
      };
    }
    case GET_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_USER_BYID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_USER_BYID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getUserByID: action.result,
      };
    }
    case GET_USER_BYID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_STORES_BY_COMPANY_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_STORES_BY_COMPANY_ID_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        storeByCompanyId: action.result,
      };
    }
    case GET_STORES_BY_COMPANY_ID_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case SEND_REVOKE_APIKEY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SEND_REVOKE_APIKEY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case SEND_REVOKE_APIKEY_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_USER_ROLE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_USER_ROLE_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case GET_USER_ROLE_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ROLE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ROLE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        roleTypes: action.result,
      };
    }
    case GET_ROLE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UNLOCK_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UNLOCK_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UNLOCK_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const addUser = (data) => {
  return {
    types: [ADD_USER_REQUESTED, ADD_USER_SUCCESS, ADD_USER_FAILURE],
    promise: client => client.post('addUsers', { data })
  };
};

export const updateUser = (data) => {
  return {
    types: [UPDATE_USER_REQUESTED, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
    promise: client => client.post('updateUsers', { data })
  };
};

export const getUserById = (id) => {
  return {
    types: [GET_USER_BYID_REQUESTED, GET_USER_BYID_SUCCESS, GET_USER_BYID_FAILURE],
    promise: client => client.get(`getUserById/${id}`)
  };
};

export const getUserByCompanyId = (companyId) => {
  return {
    types: [GET_USER_REQUESTED, GET_USER_SUCCESS, GET_USER_FAILURE],
    promise: client => client.get(`getUserByCompanyId/${companyId}`)
  };
};

export const getStoresByCompanyId = (companyId) => {
  return {
    types: [GET_STORES_BY_COMPANY_ID_REQUESTED, GET_STORES_BY_COMPANY_ID_SUCCESS, GET_STORES_BY_COMPANY_ID_FAILURE],
    promise: client => client.get(`getStoresByCompanyId/${companyId}`)
  };
};

export const sendApiKeySMS = (userId) => {
  return {
    types: [SEND_REVOKE_APIKEY_REQUESTED, SEND_REVOKE_APIKEY_SUCCESS, SEND_REVOKE_APIKEY_FAILURE],
    promise: client => client.post(`smsApiKey/${userId}`)
  };
};

export const revokeApiKeySMS = (userId) => {
  return {
    types: [SEND_REVOKE_APIKEY_REQUESTED, SEND_REVOKE_APIKEY_SUCCESS, SEND_REVOKE_APIKEY_FAILURE],
    promise: client => client.post(`revokeApiKey/${userId}`)
  };
};

export const getUserRolelist = (companyId) => {
  return {
    types: [GET_USER_ROLE_LIST_REQUESTED, GET_USER_ROLE_LIST_SUCCESS, GET_USER_ROLE_LIST_FAILURE],
    promise: client => client.get(`getUserRoleList/${companyId}`)
  };
};

export const getRoleTypes = () => {
  return {
    types: [GET_ROLE_TYPES_REQUESTED, GET_ROLE_TYPES_SUCCESS, GET_ROLE_TYPES_FAILURE],
    promise: client => client.get('getRoleTypes')
  };
};

export const deactivateUser = (userId) => {
  return {
    types: [DELETE_USER_REQUESTED, DELETE_USER_SUCCESS, DELETE_USER_FAILURE],
    promise: client => client.post(`deactivateUser/${userId}`)
  };
};

export const unlockUser = (userId) => {
  return {
    types: [UNLOCK_USER_REQUESTED, UNLOCK_USER_SUCCESS, UNLOCK_USER_FAILURE],
    promise: client => client.post(`unlockUser/${userId}`)
  };
};
