export const TIMEZONE_REQUESTED = 'utils/TIMEZONE_REQUESTED';
export const TIMEZONE_SUCCESS = 'utils/TIMEZONE_SUCCESS';
export const TIMEZONE_FAILURE = 'utils/TIMEZONE_FAILURE';

export const GET_PRINTERS_REQUESTED = 'utils/GET_PRINTERS_REQUESTED';
export const GET_PRINTERS_SUCCESS = 'utils/GET_PRINTERS_SUCCESS';
export const GET_PRINTERS_FAILURE = 'utils/GET_PRINTERS_FAILURE';

export const GET_MENU_ITEMS_REQUESTED = 'utils/GET_MENU_ITEMS_REQUESTED';
export const GET_MENU_ITEMS_SUCCESS = 'utils/GET_MENU_ITEMS_SUCCESS';
export const GET_MENU_ITEMS_FAILURE = 'utils/GET_MENU_ITEMS_FAILURE';

export const GET_MODIFIER_ITEMS_REQUESTED = 'utils/GET_MODIFIER_ITEMS_REQUESTED';
export const GET_MODIFIER_ITEMS_SUCCESS = 'utils/GET_MODIFIER_ITEMS_SUCCESS';
export const GET_MODIFIER_ITEMS_FAILURE = 'utils/GET_MODIFIER_ITEMS_FAILURE';

export const GET_DEVICE_TYPES_REQUESTED = 'utils/GET_DEVICE_TYPES_REQUESTED';
export const GET_DEVICE_TYPES_SUCCESS = 'utils/GET_DEVICE_TYPES_SUCCESS';
export const GET_DEVICE_TYPES_FAILURE = 'utils/GET_DEVICE_TYPES_FAILURE';

export const GET_SURVEY_SEGMENTS_REQUESTED = 'utils/GET_SURVEY_SEGMENTS_REQUESTED';
export const GET_SURVEY_SEGMENTS_SUCCESS = 'utils/GET_SURVEY_SEGMENTS_SUCCESS';
export const GET_SURVEY_SEGMENTS_FAILURE = 'utils/GET_SURVEY_SEGMENTS_FAILURE';

export const GET_DAYS_OF_WEEK_REQUESTED = 'utils/GET_DAYS_OF_WEEK_REQUESTED';
export const GET_DAYS_OF_WEEK_SUCCESS = 'utils/GET_DAYS_OF_WEEK_SUCCESS';
export const GET_DAYS_OF_WEEK_FAILURE = 'utils/GET_DAYS_OF_WEEK_FAILURE';


export const GET_TAXES_REQUESTED = 'utils/GET_TAXES_REQUESTED';
export const GET_TAXES_SUCCESS = 'utils/GET_TAXES_SUCCESS';
export const GET_TAXES_FAILURE = 'utils/GET_TAXES_FAILURE';

export const GET_APP_DOWNLOAD_URL_REQUESTED = 'utils/GET_APP_DOWNLOAD_URL_REQUESTED';
export const GET_APP_DOWNLOAD_URL_SUCCESS = 'utils/GET_APP_DOWNLOAD_URL_SUCCESS';
export const GET_APP_DOWNLOAD_URL_FAILURE = 'utils/GET_APP_DOWNLOAD_URL_FAILURE';

export const GET_ONE_DINE_WEAR_APP_DOWNLOAD_URL_REQUESTED = 'utils/GET_ONE_DINE_WEAR_APP_DOWNLOAD_URL_REQUESTED';
export const GET_ONE_DINE_WEAR_APP_DOWNLOAD_URL_SUCCESS = 'utils/GET_ONE_DINE_WEAR_APP_DOWNLOAD_URL_SUCCESS';
export const GET_ONE_DINE_WEAR_APP_DOWNLOAD_URL_FAILURE = 'utils/GET_ONE_DINE_WEAR_APP_DOWNLOAD_URL_FAILURE';

export const GET_ONE_DINE_SIMULATED_URL_REQUESTED = 'utils/GET_ONE_DINE_SIMULATED_URL_REQUESTED';
export const GET_ONE_DINE_SIMULATED_URL_SUCCESS = 'utils/GET_ONE_DINE_SIMULATED_URL_SUCCESS';
export const GET_ONE_DINE_SIMULATED_URL_FAILURE = 'utils/GET_ONE_DINE_SIMULATED_URL_FAILURE';

export const GET_ONE_DINE_NEXTGEN_CODE_REQUESTED = 'utils/GET_ONE_DINE_NEXTGEN_CODE_REQUESTED';
export const GET_ONE_DINE_NEXTGEN_CODE_SUCCESS = 'utils/GET_ONE_DINE_NEXTGEN_CODE_SUCCESS';
export const GET_ONE_DINE_NEXTGEN_CODE_FAILURE = 'utils/GET_ONE_DINE_NEXTGEN_CODE_FAILURE';

export const GET_ONE_DINE_NEXTGEN_SIMULATED_CODE_REQUESTED = 'utils/GET_ONE_DINE_NEXTGEN_SIMULATED_CODE_REQUESTED';
export const GET_ONE_DINE_NEXTGEN_SIMULATED_CODE_SUCCESS = 'utils/GET_ONE_DINE_NEXTGEN_SIMULATED_CODE_SUCCESS';
export const GET_ONE_DINE_NEXTGEN_SIMULATED_CODE_FAILURE = 'utils/GET_ONE_DINE_NEXTGEN_SIMULATED_CODE_FAILURE';

export const GET_MDM_DOWNLOAD_URL_REQUESTED = 'utils/GET_MDM_DOWNLOAD_URL_REQUESTED';
export const GET_MDM_DOWNLOAD_URL_SUCCESS = 'utils/GET_MDM_DOWNLOAD_URL_SUCCESS';
export const GET_MDM_DOWNLOAD_URL_FAILURE = 'utils/GET_MDM_DOWNLOAD_URL_FAILURE';

export const GET_PREFERENCES_REQUESTED = 'utils/GET_PREFERENCES_REQUESTED';
export const GET_PREFERENCES_SUCCESS = 'utils/GET_PREFERENCES_SUCCESS';
export const GET_PREFERENCES_FAILURE = 'utils/GET_PREFERENCES_FAILURE';

export const UPDATE_PREFERENCES_REQUESTED = 'utils/UPDATE_PREFERENCES_REQUESTED';
export const UPDATE_PREFERENCES_SUCCESS = 'utils/UPDATE_PREFERENCES_SUCCESS';
export const UPDATE_PREFERENCES_FAILURE = 'utils/UPDATE_PREFERENCES_FAILURE';

export const GET_BADGES_REQUESTED = 'utils/GET_BADGES_REQUESTED';
export const GET_BADGES_SUCCESS = 'utils/GET_BADGES_SUCCESS';
export const GET_BADGES_FAILURE = 'utils/GET_BADGES_FAILURE';

export const GET_MENU_ITEM_LINK_TYPES_REQUESTED = 'utils/GET_DELIVERY_ZONES_REQUESTED';
export const GET_MENU_ITEM_LINK_TYPES_SUCCESS = 'utils/GET_MENU_ITEM_LINK_TYPES_SUCCESS';
export const GET_MENU_ITEM_LINK_TYPES_FAILURE = 'utils/GET_MENU_ITEM_LINK_TYPES_FAILURE';

export const GET_DELIVERY_ZONES_REQUESTED = 'utils/GET_DELIVERY_ZONES_REQUESTED';
export const GET_DELIVERY_ZONES_SUCCESS = 'utils/GET_DELIVERY_ZONES_SUCCESS';
export const GET_DELIVERY_ZONES_FAILURE = 'utils/GET_DELIVERY_ZONES_FAILURE';

export const GET_PRINTERS_BY_BRAND_REQUESTED = 'utils/GET_PRINTERS_BY_BRAND_REQUESTED';
export const GET_PRINTERS_BY_BRAND_SUCCESS = 'utils/GET_PRINTERS_BY_BRAND_SUCCESS';
export const GET_PRINTERS_BY_BRAND_FAILURE = 'utils/GET_PRINTERS_BY_BRAND_FAILURE';

export const ADD_DEACTIVATE_REQUESTED = 'utils/ADD_DEACTIVATE_REQUESTED';
export const ADD_DEACTIVATE_SUCCESS = 'utils/ADD_DEACTIVATE_SUCCESS';
export const ADD_DEACTIVATE_FAILURE = 'utils/ADD_DEACTIVATE_FAILURE';

export const GET_CAMPAIGN_TRIGGERS_REQUESTED = 'utils/GET_CAMPAIGN_TRIGGERS_REQUESTED';
export const GET_CAMPAIGN_TRIGGERS_SUCCESS = 'utils/GET_CAMPAIGN_TRIGGERS_SUCCESS';
export const GET_CAMPAIGN_TRIGGERS_FAILURE = 'utils/GET_CAMPAIGN_TRIGGERS_FAILURE';

export const GET_ONE_DINE_MAUI_NEXTGEN_CODE_REQUESTED = 'utils/GET_ONE_DINE_MAUI_NEXTGEN_CODE_REQUESTED';
export const GET_ONE_DINE_MAUI_NEXTGEN_CODE_SUCCESS = 'utils/GET_ONE_DINE_MAUI_NEXTGEN_CODE_SUCCESS';
export const GET_ONE_DINE_MAUI_NEXTGEN_CODE_FAILURE = 'utils/GET_ONE_DINE_MAUI_NEXTGEN_CODE_FAILURE';

export const GET_ONE_DINE_MAUI_NEXTGEN_SIMULATED_CODE_REQUESTED = 'utils/GET_ONE_DINE_MAUI_NEXTGEN_SIMULATED_CODE_REQUESTED';
export const GET_ONE_DINE_MAUI_NEXTGEN_SIMULATED_CODE_SUCCESS = 'utils/GET_ONE_DINE_MAUI_NEXTGEN_SIMULATED_CODE_SUCCESS';
export const GET_ONE_DINE_MAUI_NEXTGEN_SIMULATED_CODE_FAILURE = 'utils/GET_ONE_DINE_MAUI_NEXTGEN_SIMULATED_CODE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  timezone: null,
  getPrintersByStoreId: null,
  getPrintersByBrandId: [],
  getMenuItems: [],
  getModifierItems: [],
  getDeviceType: [],
  surveySegmentsList: [],
  daysOfWeekList: [],
  taxesList: [],
  appDownloadUrl: [],
  wearAppDownloadUrl: [],
  getPreferences: [],
  badgeList: [],
  deliveryZoneType: [],
  simulatedUrl: [],
  nextGenUrl: [],
  menuItemLinkTypes: [],
  nextGensimulatedCodeUrl: [],
  getCampaignTriggerTypes: [],
  getMauiNextGenCodeUrl: [],
  getMauiNextGenSimulatedCodeUrl: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TIMEZONE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case TIMEZONE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        timezone: action.result,
      };
    }
    case TIMEZONE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_PRINTERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PRINTERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getPrintersByStoreId: action.result,
      };
    }
    case GET_PRINTERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_PRINTERS_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PRINTERS_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getPrintersByBrandId: action.result,
      };
    }
    case GET_PRINTERS_BY_BRAND_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MENU_ITEMS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ONE_DINE_WEAR_APP_DOWNLOAD_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        wearAppDownloadUrl: action.result,
      };
    }
    case GET_ONE_DINE_WEAR_APP_DOWNLOAD_URL_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ONE_DINE_WEAR_APP_DOWNLOAD_URL_FAILURE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ONE_DINE_SIMULATED_URL_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ONE_DINE_SIMULATED_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        simulatedUrl: action.result,
      };
    }
    case GET_ONE_DINE_SIMULATED_URL_FAILURE: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ONE_DINE_NEXTGEN_SIMULATED_CODE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ONE_DINE_NEXTGEN_SIMULATED_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        nextGensimulatedCodeUrl: action.result,
      };
    }
    case GET_ONE_DINE_NEXTGEN_SIMULATED_CODE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case GET_ONE_DINE_NEXTGEN_CODE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ONE_DINE_NEXTGEN_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        nextGenUrl: action.result,
      };
    }
    case GET_ONE_DINE_NEXTGEN_CODE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case GET_MENU_ITEMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getMenuItems: action.result,
      };
    }
    case GET_MENU_ITEMS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MODIFIER_ITEMS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODIFIER_ITEMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getModifierItems: action.result,
      };
    }
    case GET_MODIFIER_ITEMS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_DEVICE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_DEVICE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        getDeviceType: action.result,
      };
    }
    case GET_DEVICE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    }
    case GET_DAYS_OF_WEEK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_DAYS_OF_WEEK_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        daysOfWeekList: action.result,
      };
    }
    case GET_DAYS_OF_WEEK_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
        daysOfWeekList: [],
      };
    }
    case GET_SURVEY_SEGMENTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SURVEY_SEGMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        surveySegmentsList: action.result,
      };
    }
    case GET_SURVEY_SEGMENTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_TAXES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_TAXES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        taxesList: action.result,
      };
    }
    case GET_TAXES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        taxesList: [],
      };
    }
    case GET_APP_DOWNLOAD_URL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_APP_DOWNLOAD_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        appDownloadUrl: action.result,
      };
    }
    case GET_APP_DOWNLOAD_URL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        appDownloadUrl: [],
      };
    }
    case GET_MDM_DOWNLOAD_URL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MDM_DOWNLOAD_URL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        mdmDownloadUrl: action.result,
      };
    }
    case GET_MDM_DOWNLOAD_URL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        mdmDownloadUrl: [],
      };
    }
    case GET_PREFERENCES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PREFERENCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getPreferences: action.result.preferences,
      };
    }
    case GET_PREFERENCES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        appDownloadUrl: [],
      };
    }
    case UPDATE_PREFERENCES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_PREFERENCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPDATE_PREFERENCES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MENU_ITEM_LINK_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MENU_ITEM_LINK_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        menuItemLinkTypes: action.result,
      };
    }
    case GET_MENU_ITEM_LINK_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        menuItemLinkTypes: [],
      };
    }
    case GET_BADGES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BADGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        badgeList: action.result,
      };
    }
    case GET_BADGES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        badgeList: [],
      };
    }
    case GET_DELIVERY_ZONES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DELIVERY_ZONES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        deliveryZoneType: action.result,
      };
    }
    case GET_DELIVERY_ZONES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        deliveryZoneType: [],
      };
    }
    case ADD_DEACTIVATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_DEACTIVATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_DEACTIVATE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_CAMPAIGN_TRIGGERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CAMPAIGN_TRIGGERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getCampaignTriggerTypes: action.result,
      };
    }
    case GET_CAMPAIGN_TRIGGERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ONE_DINE_MAUI_NEXTGEN_SIMULATED_CODE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ONE_DINE_MAUI_NEXTGEN_SIMULATED_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getMauiNextGenSimulatedCodeUrl: action.result,
      };
    }
    case GET_ONE_DINE_MAUI_NEXTGEN_SIMULATED_CODE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case GET_ONE_DINE_MAUI_NEXTGEN_CODE_REQUESTED: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ONE_DINE_MAUI_NEXTGEN_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        getMauiNextGenCodeUrl: action.result,
      };
    }
    case GET_ONE_DINE_MAUI_NEXTGEN_CODE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    default:
      return {
        ...state
      };
  }
};


export const getTimezone = () => {
  return {
    types: [TIMEZONE_REQUESTED, TIMEZONE_SUCCESS, TIMEZONE_FAILURE],
    promise: client => client.get('getTimeZones'),
  };
};

export const getMenuItemLinkTypes = () => {
  return {
    types: [GET_MENU_ITEM_LINK_TYPES_REQUESTED, GET_MENU_ITEM_LINK_TYPES_SUCCESS, GET_MENU_ITEM_LINK_TYPES_FAILURE],
    promise: client => client.get('menuItemLinkTypesLookUp'),
  };
};

export const getPrintersByStoreId = (id) => {
  return {
    types: [GET_PRINTERS_REQUESTED, GET_PRINTERS_SUCCESS, GET_PRINTERS_FAILURE],
    promise: client => client.get(`getPrintersByStoreId/${id}`)
  };
};

export const getMenuItems = (storeId) => {
  return {
    types: [GET_MENU_ITEMS_REQUESTED, GET_MENU_ITEMS_SUCCESS, GET_MENU_ITEMS_FAILURE],
    promise: client => client.get(`getMenuItems/${storeId}`)
  };
};

export const getModifierItems = (storeId) => {
  return {
    types: [GET_MODIFIER_ITEMS_REQUESTED, GET_MODIFIER_ITEMS_SUCCESS, GET_MODIFIER_ITEMS_FAILURE],
    promise: client => client.get(`getModifierItems/${storeId}`)
  };
};

export const getDeviceType = () => {
  return {
    types: [GET_DEVICE_TYPES_REQUESTED, GET_DEVICE_TYPES_SUCCESS, GET_DEVICE_TYPES_FAILURE],
    promise: client => client.get('getDeviceType'),
  };
};

export const getSurveySegment = () => {
  return {
    types: [GET_SURVEY_SEGMENTS_REQUESTED, GET_SURVEY_SEGMENTS_SUCCESS, GET_SURVEY_SEGMENTS_FAILURE],
    promise: client => client.get('getSurveySegments'),
  };
};

export const getDaysOfWeek = () => {
  return {
    types: [GET_DAYS_OF_WEEK_REQUESTED, GET_DAYS_OF_WEEK_SUCCESS, GET_DAYS_OF_WEEK_FAILURE],
    promise: client => client.get('getDaysOfWeek'),
  };
};

export const getTaxesList = (brandId) => {
  return {
    types: [GET_TAXES_REQUESTED, GET_TAXES_SUCCESS, GET_TAXES_FAILURE],
    promise: client => client.get(`taxesLookUp/${brandId}`),
  };
};

export const getAppDownloadUrl = () => {
  return {
    types: [GET_APP_DOWNLOAD_URL_REQUESTED, GET_APP_DOWNLOAD_URL_SUCCESS, GET_APP_DOWNLOAD_URL_FAILURE],
    promise: client => client.get('getAppDownloadUrl'),
  };
};

export const getMDMDownloadUrl = () => {
  return {
    types: [GET_MDM_DOWNLOAD_URL_REQUESTED, GET_MDM_DOWNLOAD_URL_SUCCESS, GET_MDM_DOWNLOAD_URL_FAILURE],
    promise: client => client.get('getMDMDownloadUrl'),
  };
};

export const oneDineWearAppUrl = () => {
  return {
    types: [GET_ONE_DINE_WEAR_APP_DOWNLOAD_URL_REQUESTED, GET_ONE_DINE_WEAR_APP_DOWNLOAD_URL_SUCCESS, GET_ONE_DINE_WEAR_APP_DOWNLOAD_URL_FAILURE],
    promise: client => client.get('wearApp'),
  };
};

export const getSimulated = () => {
  return {
    types: [GET_ONE_DINE_SIMULATED_URL_REQUESTED, GET_ONE_DINE_SIMULATED_URL_SUCCESS, GET_ONE_DINE_SIMULATED_URL_FAILURE],
    promise: client => client.get('simulated'),
  };
};

export const getNextGenCode = () => {
  return {
    types: [GET_ONE_DINE_NEXTGEN_CODE_REQUESTED, GET_ONE_DINE_NEXTGEN_CODE_SUCCESS, GET_ONE_DINE_NEXTGEN_CODE_FAILURE],
    promise: client => client.get('nextGen'),
  };
};

export const getNextGenSimulatedCode = () => {
  return {
    types: [GET_ONE_DINE_NEXTGEN_SIMULATED_CODE_REQUESTED, GET_ONE_DINE_NEXTGEN_SIMULATED_CODE_SUCCESS, GET_ONE_DINE_NEXTGEN_SIMULATED_CODE_FAILURE],
    promise: client => client.get('nextGenSimulated'),
  };
};

export const getPreferences = () => {
  return {
    types: [GET_PREFERENCES_REQUESTED, GET_PREFERENCES_SUCCESS, GET_PREFERENCES_FAILURE],
    promise: client => client.get('getPreferences'),
  };
};

export const updatePreferences = (data) => {
  return {
    types: [UPDATE_PREFERENCES_REQUESTED, UPDATE_PREFERENCES_SUCCESS, UPDATE_PREFERENCES_FAILURE],
    promise: client => client.post('updatePreferences', {
      data
    }),
  };
};
export const getBadgesList = () => {
  return {
    types: [GET_BADGES_REQUESTED, GET_BADGES_SUCCESS, GET_BADGES_FAILURE],
    promise: client => client.get('badgesLookUp'),
  };
};

export const getDeliveryZoneTypes = () => {
  return {
    types: [GET_DELIVERY_ZONES_REQUESTED, GET_DELIVERY_ZONES_SUCCESS, GET_DELIVERY_ZONES_FAILURE],
    promise: client => client.get('getDeliveryZoneTypes'),
  };
};

export const getPrintersByBrandId = (brandId) => {
  return {
    types: [GET_PRINTERS_BY_BRAND_REQUESTED, GET_PRINTERS_BY_BRAND_SUCCESS, GET_PRINTERS_BY_BRAND_FAILURE],
    promise: client => client.get(`getPrinterDevices/${brandId}`)
  };
};

export const addDeactivate = (type, idType, id, data) => {
  return {
    types: [ADD_DEACTIVATE_REQUESTED, ADD_DEACTIVATE_SUCCESS, ADD_DEACTIVATE_FAILURE],
    promise: client => client.post(`deactivate/${type}/deactivate/${idType}/${id}`, { data }),
  };
};


export const getCampaignTriggersTypes = () => {
  return {
    types: [GET_CAMPAIGN_TRIGGERS_REQUESTED, GET_CAMPAIGN_TRIGGERS_SUCCESS, GET_CAMPAIGN_TRIGGERS_FAILURE],
    promise: client => client.get('getCampaignTriggersTypes'),
  };
};

export const getMauiNextGenCode = () => {
  return {
    types: [GET_ONE_DINE_MAUI_NEXTGEN_CODE_REQUESTED, GET_ONE_DINE_MAUI_NEXTGEN_CODE_SUCCESS, GET_ONE_DINE_MAUI_NEXTGEN_CODE_FAILURE],
    promise: client => client.get('getMauiNextGen'),
  };
};

export const getMauiNextGenSimulatedCode = () => {
  return {
    types: [GET_ONE_DINE_MAUI_NEXTGEN_SIMULATED_CODE_REQUESTED, GET_ONE_DINE_MAUI_NEXTGEN_SIMULATED_CODE_SUCCESS, GET_ONE_DINE_MAUI_NEXTGEN_SIMULATED_CODE_FAILURE],
    promise: client => client.get('getMauiNextGenSimulated'),
  };
};
