import CurbsideLandingScreen from '@assets/MWebScreens/CurbsideLandingScreen.png';
import WalkUpToggleLandingScreen from '@assets/MWebScreens/WalkUpToggleLandingScreen.png';
import OnlineOrdersAndAdvDelivery from '@assets/MWebScreens/OnlineOrdersAndAdvDelivery.png';
import AccountMyData from '@assets/MWebScreens/AccountMyData.png';
import MyAccountScreen from '@assets/MWebScreens/MyAccountScreen.png';
import AccountProfileScreen from '@assets/MWebScreens/AccountProfileScreen.png';
import AccountPaymentScreen from '@assets/MWebScreens/AccountPaymentScreen.png';
import AccountPastOrdersScreen from '@assets/MWebScreens/AccountPastOrdersScreen.png';
import AccountLoyaltyScreen from '@assets/MWebScreens/AccountLoyaltyScreen.png';
import AccountAddressBook from '@assets/MWebScreens/AccountAddressBook.png';
import OptInScreen from '@assets/MWebScreens/OptInScreen.png';
import OrderScreen from '@assets/MWebScreens/OrderScreen.png';
import UpsellScreen from '@assets/MWebScreens/UpsellScreen.png';
import Badge from '@assets/MWebScreens/Badge.png';
import CheckoutScreen from '@assets/MWebScreens/CheckoutScreen.png';

export default {
  CurbsideLandingScreen: {
    screenLabel: 'Curbside - Landing Screen',
    image: [CurbsideLandingScreen],
    customLabels: [
      {
        defaultText: 'Spot Number',
        key: 'label.CLS_SPOT_NUMBER',
        type: 'label',
        value: 'CLS_SPOT_NUMBER',
      },
      {
        defaultText: 'Next',
        key: 'label.CLS_NEXT',
        type: 'label',
        value: 'CLS_NEXT',
      },
      {
        defaultText: 'Orders are currently taking',
        key: 'label.CLS_ORDER_COMPLETE_MSG',
        type: 'label',
        value: 'CLS_ORDER_COMPLETE_MSG',
      },
      {
        defaultText: 'Minutes',
        key: 'label.CLS_MINUTES',
        type: 'label',
        value: 'CLS_MINUTES',
      },
      {
        defaultText: 'Please enter your spot number',
        key: 'customMessage.CLS_ERR_SPOT_NUMBER',
        type: 'customMessage',
        value: 'CLS_ERR_SPOT_NUMBER',
      },
    ]
  },
  WalkUpScreen: {
    screenLabel: 'Walkup - Landing Screen',
    image: [WalkUpToggleLandingScreen],
    customLabels: [
      {
        defaultText: 'Dine In',
        key: 'label.WLS_DINE_IN',
        type: 'label',
        value: 'WLS_DINE_IN',
      },
      {
        defaultText: 'Takeout',
        key: 'label.WLS_TAKE_OUT',
        type: 'label',
        value: 'WLS_TAKE_OUT',
      },
      {
        defaultText: 'Name',
        key: 'label.WLS_NAME',
        type: 'label',
        value: 'WLS_NAME',
      },
      {
        defaultText: 'Orders are currently taking',
        key: 'label.WLS_ORDER_COMPLETE_MSG',
        type: 'label',
        value: 'WLS_ORDER_COMPLETE_MSG',
      },
      {
        defaultText: 'Minutes',
        key: 'label.WLS_MINUTES',
        type: 'label',
        value: 'WLS_MINUTES',
      },
      {
        defaultText: 'Next',
        key: 'label.WLS_NEXT',
        type: 'label',
        value: 'WLS_NEXT',
      },
      {
        defaultText: 'Name is required',
        key: 'customMessage.WLS_ERR_NAME_REQUIRED',
        type: 'customMessage',
        value: 'WLS_ERR_NAME_REQUIRED',
      },
    ]
  },
  OnlineOrdersAndAdvDelivery: {
    screenLabel: 'Online Orders and Advance Delivery - Landing Screen',
    image: [OnlineOrdersAndAdvDelivery],
    customLabels: [
      {
        defaultText: 'Pick up',
        key: 'label.OLS_PICK_UP',
        type: 'label',
        value: 'OLS_PICK_UP',
      },
      {
        defaultText: 'Date & Time',
        key: 'label.OLS_ DATE_TIME_INPUT_LABEL',
        type: 'label',
        value: 'OLS_ DATE_TIME_INPUT_LABEL',
      },
      {
        defaultText: 'Vehicle Description (Optional)',
        key: 'label.OLS_PICKUP_VEHICLE_DESCRIPTION',
        type: 'label',
        value: 'OLS_PICKUP_VEHICLE_DESCRIPTION',
      },
      {
        defaultText: 'Select Pickup Date',
        key: 'label.OLS_SELECT_PICK_UP_DATE',
        type: 'label',
        value: 'OLS_SELECT_PICK_UP_DATE',
      },
      {
        defaultText: 'Select Pickup Time',
        key: 'label.OLS_SELECT_PICK_UP_TIME',
        type: 'label',
        value: 'OLS_SELECT_PICK_UP_TIME',
      },
      {
        defaultText: 'Next',
        key: 'label.OLS_NEXT',
        type: 'label',
        value: 'OLS_NEXT',
      },
      {
        defaultText: 'Schedule',
        key: 'label.OLS_SCHEDULE',
        type: 'label',
        value: 'OLS_SCHEDULE',
      },
      {
        defaultText: 'Apply',
        key: 'label.OLS_APPLY',
        type: 'label',
        value: 'OLS_APPLY',
      },
      {
        defaultText: 'Cancel',
        key: 'label.OLS_CANCEL',
        type: 'label',
        value: 'OLS_CANCEL',
      },
      {
        defaultText: 'Delivery',
        key: 'label.OLS_DELIVERY',
        type: 'label',
        value: 'OLS_DELIVERY',
      },
      {
        defaultText: 'Delivery Address',
        key: 'label.OLS_DELIVERY_ADDRESS',
        type: 'label',
        value: 'OLS_DELIVERY_ADDRESS',
      },
      {
        defaultText: 'Apartment suite (Optional)',
        key: 'label.OLS_APARTMENT_SUITE_INPUT_LABEL',
        type: 'label',
        value: 'OLS_APARTMENT_SUITE_INPUT_LABEL',
      },
      {
        defaultText: 'Select Delivery Date',
        key: 'label.OLS_SELECT_DELIVERY_DATE',
        type: 'label',
        value: 'OLS_SELECT_DELIVERY_DATE',
      },
      {
        defaultText: 'Select Delivery Time',
        key: 'label.OLS_SELECT_DELIVERY_TIME',
        type: 'label',
        value: 'OLS_SELECT_DELIVERY_TIME',
      },
      {
        defaultText: 'Please select an address',
        key: 'label.OLS_PLEASE_SELECT_AN_ADDRESS',
        type: 'label',
        value: 'OLS_PLEASE_SELECT_AN_ADDRESS',
      },
      {
        defaultText: 'Done',
        key: 'label.OLS_DELIVERY_ADDRESS_DONE',
        type: 'label',
        value: 'OLS_DELIVERY_ADDRESS_DONE',
      },
      {
        defaultText: 'Delivery fee',
        key: 'label.OLS_DELIVERY_FEE',
        type: 'label',
        value: 'OLS_DELIVERY_FEE',
      },
      {
        defaultText: 'waived for orders above',
        key: 'label.OLS_DELIVERY_WAIVED_ORDERS',
        type: 'label',
        value: 'OLS_DELIVERY_WAIVED_ORDERS',
      },
      {
        defaultText: 'Minimum Order',
        key: 'label.OLS_DELIVERY_MINIMUM_ORDER',
        type: 'label',
        value: 'OLS_DELIVERY_MINIMUM_ORDER',
      },
      {
        defaultText: 'Please enter Date and Time',
        key: 'customMessage.OLS_ERR_ENTER_DATE_TIME',
        type: 'customMessage',
        value: 'OLS_ERR_ENTER_DATE_TIME',
      },
      {
        defaultText: 'Please enter Date',
        key: 'customMessage.OLS_ERR_PICKUP_DATE',
        type: 'customMessage',
        value: 'OLS_ERR_PICKUP_DATE',
      },
      {
        defaultText: 'Select Time field is required',
        key: 'customMessage.OLS_ERR_DELIVERY_TIME',
        type: 'customMessage',
        value: 'OLS_ERR_DELIVERY_TIME',
      },
      {
        defaultText: 'Delivery address is required',
        key: 'customMessage.OLS_ERR_DELIVERY_ADDRESS',
        type: 'customMessage',
        value: 'OLS_ERR_DELIVERY_ADDRESS',
      },
      {
        defaultText: 'Please enter Date',
        key: 'customMessage.OLS_ERR_DELIVERY_DATE',
        type: 'customMessage',
        value: 'OLS_ERR_DELIVERY_DATE',
      },
      {
        defaultText: 'Select Time field is required',
        key: 'customMessage.OLS_ERR_DELIVERY_TIME',
        type: 'customMessage',
        value: 'OLS_ERR_DELIVERY_TIME',
      },
      {
        defaultText: 'Date and Time must be in the future',
        key: 'customMessage.OLS_ERR_DATE_TIME_FUTURE',
        type: 'customMessage',
        value: 'OLS_ERR_DATE_TIME_FUTURE',
      },
    ]
  },
  AccountMyData: {
    screenLabel: 'Account - My Data',
    image: [AccountMyData],
    customLabels: [

      {
        defaultText: 'Enter Mobile Number',
        key: 'label.AMD_ENTER_MOBILE_NUMBER',
        type: 'label',
        value: 'AMD_ENTER_MOBILE_NUMBER',
      },
      {
        defaultText: 'Email Address',
        key: 'label.AMD_EMAIL_ADDRESS',
        type: 'label',
        value: 'AMD_EMAIL_ADDRESS',
      },
      {
        defaultText: 'First Name',
        key: 'label.AMD_FIRST_NAME',
        type: 'label',
        value: 'AMD_FIRST_NAME',
      },
      {
        defaultText: 'Last Name',
        key: 'label.AMD_LAST_NAME',
        type: 'label',
        value: 'AMD_LAST_NAME',
      },
      {
        defaultText: 'Request data',
        key: 'label.AMD_REQUEST_DATA',
        type: 'label',
        value: 'AMD_REQUEST_DATA',
      },
      {
        defaultText: 'Delete data',
        key: 'label.AMD_DELETE_DATA',
        type: 'label',
        value: 'AMD_DELETE_DATA',
      },
      {
        defaultText: 'Comment',
        key: 'label.AMD_COMMENT',
        type: 'label',
        value: 'AMD_COMMENT',
      },
      {
        defaultText: 'Submit',
        key: 'label.AMD_SUBMIT',
        type: 'label',
        value: 'AMD_SUBMIT',
      },
      {
        defaultText: 'Confirmation',
        key: 'label.AMD_CONFIRMATION',
        type: 'label',
        value: 'AMD_CONFIRMATION',
      },
      {
        defaultText: 'Yes',
        key: 'label.AMD_YES',
        type: 'label',
        value: 'AMD_YES',
      },
      {
        defaultText: 'Cancel',
        key: 'label.AMD_CANCEL',
        type: 'label',
        value: 'AMD_CANCEL',
      },
      {
        defaultText: 'What would you like to do',
        key: 'customMessage.AMD_MY_DATA_ACTION_MESSAGE',
        type: 'customMessage',
        value: 'AMD_MY_DATA_ACTION_MESSAGE',
      },
      {
        defaultText: 'Are you sure you want to submit the form',
        key: 'customMessage.AMD_MY_DATA_CONFIRMATION_MESSAGE',
        type: 'customMessage',
        value: 'AMD_MY_DATA_CONFIRMATION_MESSAGE',
      },
    ]
  },
  MyAccountScreen: {
    screenLabel: 'My Account Screen',
    image: [MyAccountScreen],
    customLabels: [
      {
        defaultText: 'My Account',
        key: 'label.AMA_MY_ACCOUNT',
        type: 'label',
        value: 'AMA_MY_ACCOUNT',
      },
      {
        defaultText: 'Profile',
        key: 'label.AMA_PROFILE',
        type: 'label',
        value: 'AMA_PROFILE',
      },
      {
        defaultText: 'Payments',
        key: 'label.AMA_PAYMENTS',
        type: 'label',
        value: 'AMA_PAYMENTS',
      },
      {
        defaultText: 'Loyalty Accounts',
        key: 'label.AMA_LOYALTY_ACCOUNTS',
        type: 'label',
        value: 'AMA_LOYALTY_ACCOUNTS',
      },
      {
        defaultText: 'Opt In',
        key: 'label.AMA_OPT_IN',
        type: 'label',
        value: 'AMA_OPT_IN',
      },
      {
        defaultText: 'Address Book',
        key: 'label.AMA_ADDRESS_BOOK',
        type: 'label',
        value: 'AMA_ADDRESS_BOOK',
      },
      {
        defaultText: 'Past Orders',
        key: 'label.AMA_PAST_ORDERS',
        type: 'label',
        value: 'AMA_PAST_ORDERS',
      },
      {
        defaultText: 'Sign Out',
        key: 'label.AMA_SIGN_OUT',
        type: 'label',
        value: 'AMA_SIGN_OUT',
      },
      {
        defaultText: 'Privacy Policy',
        key: 'label.AMA_PRIVACY_POLICY',
        type: 'label',
        value: 'AMA_PRIVACY_POLICY',
      },
      {
        defaultText: 'Terms and Conditions',
        key: 'label.AMA_TERMS_AND_CONDITION',
        type: 'label',
        value: 'AMA_TERMS_AND_CONDITION',
      },
      {
        defaultText: 'Cookies',
        key: 'label.AMA_COOKIES',
        type: 'label',
        value: 'AMA_COOKIES',
      },
      {
        defaultText: 'My Data',
        key: 'label.AMA_MY_DATA',
        type: 'label',
        value: 'AMA_MY_DATA',
      },
      {
        defaultText: 'Version',
        key: 'label.AMA_VERSION',
        type: 'label',
        value: 'AMA_VERSION',
      },
    ]
  },
  MyAccountProfileScreen: {
    screenLabel: 'Account - Profile Settings',
    image: [AccountProfileScreen],
    customLabels: [
      {
        defaultText: 'Profile Settings',
        key: 'label.APS_PROFILE_SETTINGS',
        type: 'label',
        value: 'APS_PROFILE_SETTINGS',
      },
      {
        defaultText: 'User Name',
        key: 'label.APS_USER_NAME',
        type: 'label',
        value: 'APS_USER_NAME',
      },
      {
        defaultText: 'First Name',
        key: 'label.APS_FIRST_NAME',
        type: 'label',
        value: 'APS_FIRST_NAME',
      },
      {
        defaultText: 'Last Name',
        key: 'label.APS_LAST_NAME',
        type: 'label',
        value: 'APS_LAST_NAME',
      },
      {
        defaultText: 'Order Nickname',
        key: 'label.APS_ORDER_NICKNAME',
        type: 'label',
        value: 'APS_ORDER_NICKNAME',
      },
      {
        defaultText: 'Phone Number',
        key: 'label.APS_PHONE_NUMBER',
        type: 'label',
        value: 'APS_PHONE_NUMBER',
      },
      {
        defaultText: 'Email Address',
        key: 'label.APS_EMAIL_ADDRESS',
        type: 'label',
        value: 'APS_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Save Changes',
        key: 'label.APS_SAVE_CHANGES',
        type: 'label',
        value: 'APS_SAVE_CHANGES',
      },
      {
        defaultText: 'Password reset',
        key: 'label.APS_PASSWORD_RESET',
        type: 'label',
        value: 'APS_PASSWORD_RESET',
      },
      {
        defaultText: 'Change Your Password',
        key: 'label.APS_CHANGE_PASSWORD',
        type: 'label',
        value: 'APS_CHANGE_PASSWORD',
      },
      {
        defaultText: 'Current Password',
        key: 'label.APS_CURRENT_PASSWORD',
        type: 'label',
        value: 'APS_CURRENT_PASSWORD',
      },
      {
        defaultText: 'New Password',
        key: 'label.APS_NEW_PASSWORD',
        type: 'label',
        value: 'APS_NEW_PASSWORD',
      },
      {
        defaultText: 'Confirm Password',
        key: 'label.APS_CONFIRM_PASSWORD',
        type: 'label',
        value: 'APS_CONFIRM_PASSWORD',
      },
      {
        defaultText: 'Your password must meet the following',
        key: 'customMessage.APS_PASSWORD_MUST_MEET_THE_FOLLOWING',
        type: 'customMessage',
        value: 'APS_PASSWORD_MUST_MEET_THE_FOLLOWING',
      },
      {
        defaultText: 'be 8 to 15 character long',
        key: 'customMessage.APS_EIGHT_CHARACTER_LONG',
        type: 'customMessage',
        value: 'APS_EIGHT_CHARACTER_LONG',
      },
      {
        defaultText: 'contain a mix of upper and lower case letters',
        key: 'customMessage.APS_HAVE_UPPER_LOWER_CASE',
        type: 'customMessage',
        value: 'APS_HAVE_UPPER_LOWER_CASE',
      },
      {
        defaultText: 'contain at least one number',
        key: 'customMessage.APS_AT_LEAST_ONE_NUMBER',
        type: 'customMessage',
        value: 'APS_AT_LEAST_ONE_NUMBER',
      },
      {
        defaultText: 'contain at least one special character',
        key: 'customMessage.APS_AT_LEAST_ONE_SPECIAL_CHARACTER',
        type: 'customMessage',
        value: 'APS_AT_LEAST_ONE_SPECIAL_CHARACTER',
      },
      {
        defaultText: 'First name is required',
        key: 'customMessage.APS_ERR_FIRST_NAME',
        type: 'customMessage',
        value: 'APS_ERR_FIRST_NAME',
      },
      {
        defaultText: 'Last name is required',
        key: 'customMessage.APS_ERR_LAST_NAME',
        type: 'customMessage',
        value: 'APS_ERR_LAST_NAME',
      },
      {
        defaultText: 'Email is required',
        key: 'customMessage.APS_ERR_EMAIL_ADDRESS',
        type: 'customMessage',
        value: 'APS_ERR_EMAIL_ADDRESS',
      },
      {
        defaultText: 'password is required',
        key: 'customMessage.APS_ERR_CURRENT_PASSWORD',
        type: 'customMessage',
        value: 'APS_ERR_CURRENT_PASSWORD',
      },
      {
        defaultText: 'new password is required',
        key: 'customMessage.APS_ERR_NEW_PASSWORD',
        type: 'customMessage',
        value: 'APS_ERR_NEW_PASSWORD',
      }, {
        defaultText: 'confirm password is required',
        key: 'customMessage.APS_ERR_CONFIRM_PASSWORD',
        type: 'customMessage',
        value: 'APS_ERR_CONFIRM_PASSWORD',
      },
    ]
  },
  MyAccountPaymentScreen: {
    screenLabel: 'Account - Payment Screen',
    image: [AccountPaymentScreen],
    customLabels: [
      {
        defaultText: 'Manage payment methods',
        key: 'label.APM_PAYMENT_METHODS',
        type: 'label',
        value: 'APM_PAYMENT_METHODS',
      },
      {
        defaultText: 'Update Card Name',
        key: 'label.APM_UPDATE_NAME',
        type: 'label',
        value: 'APM_UPDATE_NAME',
      },
      {
        defaultText: 'Enter Card Name',
        key: 'label.APM_ENTER_CARD_NAME',
        type: 'label',
        value: 'APM_ENTER_CARD_NAME',
      },
      {
        defaultText: 'Save',
        key: 'label.APM_SAVE',
        type: 'label',
        value: 'APM_SAVE',
      },
      {
        defaultText: 'Cancel',
        key: 'label.APM_CANCEL',
        type: 'label',
        value: 'APM_CANCEL',
      },
      {
        defaultText: 'No',
        key: 'label.APM_NO',
        type: 'label',
        value: 'APM_NO',
      },
      {
        defaultText: 'Delete Card',
        key: 'label.APM_DELETE_CARD',
        type: 'label',
        value: 'APM_DELETE_CARD',
      },
      {
        defaultText: 'Yes',
        key: 'label.APM_YES',
        type: 'label',
        value: 'APM_YES',
      },
      {
        defaultText: 'Are you sure you want to delete the card permanently. This action cannot be undone',
        key: 'customMessage.APM_PAYMENT_DELETE_MESSAGE',
        type: 'customMessage',
        value: 'APM_PAYMENT_DELETE_MESSAGE',
      },
      {
        defaultText: 'Your wallet is empty',
        key: 'customMessage.APM_WALLETE_EMPTY',
        type: 'customMessage',
        value: 'APM_WALLETE_EMPTY',
      },
      {
        defaultText: 'Please enter the name',
        key: 'customMessage.APM_ERR_ENTER_NAME',
        type: 'customMessage',
        value: 'APM_ERR_ENTER_NAME',
      }
    ]
  },
  AccountAddressBookScreen: {
    screenLabel: 'Account - Address Book',
    image: [AccountAddressBook],
    customLabels: [
      {
        defaultText: 'Address Book',
        key: 'label.AAB_ADDRESS_BOOK',
        type: 'label',
        value: 'AAB_ADDRESS_BOOK',
      },
      {
        defaultText: 'Add Address',
        key: 'label.AAB_ADD_ADDRESS',
        type: 'label',
        value: 'AAB_ADD_ADDRESS',
      },
      {
        defaultText: 'Search Address',
        key: 'label.AAB_SEARCH_ADRESS',
        type: 'label',
        value: 'AAB_SEARCH_ADRESS',
      },
      {
        defaultText: 'Enter Name',
        key: 'label.AAB_ENTER_NAME',
        type: 'label',
        value: 'AAB_ENTER_NAME',
      },
      {
        defaultText: 'Cancel',
        key: 'label.AAB_CANCEL',
        type: 'label',
        value: 'AAB_CANCEL',
      },
      {
        defaultText: 'Save',
        key: 'label.AAB_SAVE',
        type: 'label',
        value: 'AAB_SAVE',
      },
      {
        defaultText: 'Edit Address',
        key: 'label.AAB_EDIT_ADDRESS',
        type: 'label',
        value: 'AAB_EDIT_ADDRESS',
      },
      {
        defaultText: 'Address',
        key: 'label.AAB_ADDRESS',
        type: 'label',
        value: 'AAB_ADDRESS',
      },
      {
        defaultText: 'Name',
        key: 'label.AAB_NAME',
        type: 'label',
        value: 'AAB_NAME',
      },
      {
        defaultText: 'Delete Address',
        key: 'label.AAB_DELETE_ADDRESS',
        type: 'label',
        value: 'AAB_DELETE_ADDRESS',
      },
      {
        defaultText: 'Delete',
        key: 'label.AAB_DELETE',
        type: 'label',
        value: 'AAB_DELETE',
      },
      {
        defaultText: 'Are you sure you want to delete the Address permanently. This action cannot be undone',
        key: 'customMessage.AAB_OPTIN_DELETE_MESSAGE',
        type: 'customMessage',
        value: 'AAB_OPTIN_DELETE_MESSAGE',
      },
      {
        defaultText: 'No addresses found',
        key: 'customMessage.AAB_NO_ADDRESS_FOUND',
        type: 'customMessage',
        value: 'AAB_NO_ADDRESS_FOUND',
      },
    ]
  },
  AccountPastOrdersScreen: {
    screenLabel: 'Account - Past Orders Screen',
    image: [AccountPastOrdersScreen],
    customLabels: [
      {
        defaultText: 'Past Orders',
        key: 'label.APO_PAST_ORDERS',
        type: 'label',
        value: 'APO_PAST_ORDERS',
      },
      {
        defaultText: 'View Details',
        key: 'label.APO_VIEW_DETAILS',
        type: 'label',
        value: 'APO_VIEW_DETAILS',
      },
      {
        defaultText: 'Item Total',
        key: 'label.APO_ITEM_TOTAL',
        type: 'label',
        value: 'APO_ITEM_TOTAL',
      },
      {
        defaultText: 'Delivery fee',
        key: 'label.APO_DELIVERY_FEE',
        type: 'label',
        value: 'APO_DELIVERY_FEE',
      },
      {
        defaultText: 'Tip',
        key: 'label.APO_TIP',
        type: 'label',
        value: 'APO_TIP',
      },
      {
        defaultText: 'Tax & Restaurant Charges',
        key: 'label.APO_TAX_RESTAURANT_CHARGES',
        type: 'label',
        value: 'APO_TAX_RESTAURANT_CHARGES',
      },
      {
        defaultText: 'Total',
        key: 'label.APO_TOTAL',
        type: 'label',
        value: 'APO_TOTAL',
      },
      {
        defaultText: 'You haven\'t placed any orders yet',
        key: 'customMessage.APO_PAST_ORDERS_EMPTY',
        type: 'customMessage',
        value: 'APO_PAST_ORDERS_EMPTY',
      },
    ]
  },
  AccountLoyaltyScreen: {
    screenLabel: 'Account - Loyalty Screen',
    image: [AccountLoyaltyScreen],
    customLabels: [
      {
        defaultText: 'Loyalty Accounts',
        key: 'label.ALA_LOYALTY_ACCOUNTS',
        type: 'label',
        value: 'ALA_LOYALTY_ACCOUNTS',
      },
      {
        defaultText: 'Update Card Name',
        key: 'label.ALA_UPDATE_NAME',
        type: 'label',
        value: 'ALA_UPDATE_NAME',
      },
      {
        defaultText: 'Enter Card Name',
        key: 'label.ALA_ENTER_CARD_NAME',
        type: 'label',
        value: 'ALA_ENTER_CARD_NAME',
      },
      {
        defaultText: 'Save',
        key: 'label.ALA_SAVE',
        type: 'label',
        value: 'ALA_SAVE',
      },
      {
        defaultText: 'Cancel',
        key: 'label.ALA_CANCEL',
        type: 'label',
        value: 'ALA_CANCEL',
      },
      {
        defaultText: 'Delete Card',
        key: 'label.ALA_DELETE_CARD',
        type: 'label',
        value: 'ALA_DELETE_CARD',
      },
      {
        defaultText: 'Yes',
        key: 'label.ALA_YES',
        type: 'label',
        value: 'ALA_YES',
      },
      {
        defaultText: 'No',
        key: 'label.ALA_NO',
        type: 'label',
        value: 'ALA_NO',
      },
      {
        defaultText: 'Are you sure you want to delete the card permanently. This action cannot be undone',
        key: 'customMessage.ALA_PAYMENT_DELETE_MESSAGE',
        type: 'customMessage',
        value: 'ALA_PAYMENT_DELETE_MESSAGE',
      },
      {
        defaultText: 'No offers currently available',
        key: 'customMessage.ALA_NO_OFFERS_AVAILABLE',
        type: 'customMessage',
        value: 'ALA_NO_OFFERS_AVAILABLE',
      },
      {
        defaultText: 'Please enter the name',
        key: 'customMessage.ALA_ERR_ENTER_NAME',
        type: 'customMessage',
        value: 'ALA_ERR_ENTER_NAME',
      },
    ]
  },
  AccountOptInScreen: {
    screenLabel: 'Account - Opt In Screen',
    image: [OptInScreen],
    customLabels: [
      {
        defaultText: 'Opt In',
        key: 'label.AOI_OPT_IN',
        type: 'label',
        value: 'AOI_OPT_IN',
      },
      {
        defaultText: 'Opt in to receive communication',
        key: 'label.AOI_RECIVE_COMMUNICATION',
        type: 'label',
        value: 'AOI_RECIVE_COMMUNICATION',
      },
      {
        defaultText: 'Phone Number',
        key: 'label.AOI_PHONE_NUMBER',
        type: 'label',
        value: 'AOI_PHONE_NUMBER',
      },
      {
        defaultText: 'Email Address',
        key: 'label.AOI_EMAIL_ADDRESS',
        type: 'label',
        value: 'AOI_EMAIL_ADDRESS',
      },
      {
        defaultText: 'Save',
        key: 'label.AOI_SAVE',
        type: 'label',
        value: 'AOI_SAVE',
      },
      {
        defaultText: 'Please enter valid phone number',
        key: 'customMessage.AOI_ERR_PHONE_NUMBER',
        type: 'customMessage',
        value: 'AOI_ERR_PHONE_NUMBER',
      },
      {
        defaultText: 'Please enter valid email address',
        key: 'customMessage.AOI_ERR_EMAIL_ADDRESS',
        type: 'customMessage',
        value: 'AOI_ERR_EMAIL_ADDRESS',
      },
    ]
  },
  AccountOrderScreen: {
    screenLabel: 'Orders Screen',
    image: [OrderScreen],
    customLabels: [
      {
        defaultText: 'Search',
        key: 'label.ORS_SEARCH',
        type: 'label',
        value: 'ORS_SEARCH',
      },
      {
        defaultText: 'View',
        key: 'label.ORS_VIEW',
        type: 'label',
        value: 'ORS_VIEW',
      },
      {
        defaultText: 'ORDER',
        key: 'label.ORS_ORDER',
        type: 'label',
        value: 'ORS_ORDER',
      },
      {
        defaultText: 'more',
        key: 'label.ORS_MORE',
        type: 'label',
        value: 'ORS_MORE',
      },
      {
        defaultText: 'CAL',
        key: 'label.ORS_CAL',
        type: 'label',
        value: 'ORS_CAL',
      },
      {
        defaultText: 'No',
        key: 'label.ORS_NO',
        type: 'label',
        value: 'ORS_NO',
      },
      {
        defaultText: 'YES',
        key: 'label.ORS_YES',
        type: 'label',
        value: 'ORS_YES',
      },
      {
        defaultText: 'Are you sure, you want to delete this item?',
        key: 'customMessage.ORS_ORDER_DELETE',
        type: 'customMessage',
        value: 'ORS_ORDER_DELETE',
      },
    ]
  },
  UpsellScreen: {
    screenLabel: 'Upsell Screen',
    image: [UpsellScreen],
    customLabels: [
      {
        defaultText: 'Order',
        key: 'label.USS_ORDER',
        type: 'label',
        value: 'USS_ORDER',
      },
      {
        defaultText: 'Skip',
        key: 'label.USS_SKIP',
        type: 'label',
        value: 'USS_SKIP',
      },
      {
        defaultText: 'Continue',
        key: 'label.USS_CONTINUE',
        type: 'label',
        value: 'ORS_ORDER',
      },
      {
        defaultText: 'Yes',
        key: 'label.USS_YES',
        type: 'label',
        value: 'USS_YES',
      },
      {
        defaultText: 'No',
        key: 'label.USS_NO',
        type: 'label',
        value: 'USS_NO',
      },
      {
        defaultText: 'You May Also Like?',
        key: 'customMessage.USS_UPSELL_TITLE',
        type: 'customMessage',
        value: 'USS_UPSELL_TITLE',
      },
      {
        defaultText: 'Are you sure, you want to delete this item?',
        key: 'customMessage.USS_UPSELL_DELETE',
        type: 'customMessage',
        value: 'USS_UPSELL_DELETE',
      },
    ]
  },
  Badge: {
    screenLabel: 'Badge',
    image: [Badge],
    customLabels: [
      {
        defaultText: 'Out of Stock',
        key: 'label.BDG_OUT_OF_STOCK',
        type: 'label',
        value: 'BDG_OUT_OF_STOCK',
      },
      {
        defaultText: 'View Only',
        key: 'label.BDG_VIEW_ONLY',
        type: 'label',
        value: 'BDG_VIEW_ONLY',
      },
      {
        defaultText: 'New Item',
        key: 'label.BDG_NEW_ITEM',
        type: 'label',
        value: 'BDG_NEW_ITEM',
      },
      {
        defaultText: 'Limited Time',
        key: 'label.BDG_LIMITED_TIME',
        type: 'label',
        value: 'BDG_LIMITED_TIME',
      },
      {
        defaultText: 'Featured',
        key: 'label.BDG_FEATURED',
        type: 'label',
        value: 'BDG_FEATURED',
      },
      {
        defaultText: 'Special',
        key: 'label.BDG_SPECIAL',
        type: 'label',
        value: 'BDG_SPECIAL',
      },
      {
        defaultText: 'Coming Soon',
        key: 'label.BDG_COMING_SOON',
        type: 'label',
        value: 'BDG_COMING_SOON',
      },
      {
        defaultText: 'Limited Quantity',
        key: 'label.BDG_LIMITED_QUANTITY',
        type: 'label',
        value: 'BDG_LIMITED_QUANTITY',
      },
      {
        defaultText: 'Additional Time',
        key: 'label.BDG_ADDITIONAL_TIME',
        type: 'label',
        value: 'BDG_ADDITIONAL_TIME',
      },
      {
        defaultText: 'Time Restricted',
        key: 'label.BDG_TIME_RESTRICTED',
        type: 'label',
        value: 'BDG_TIME_RESTRICTED',
      },
    ]
  },
  CheckoutScreen: {
    screenLabel: 'CheckoutScreen',
    image: [CheckoutScreen],
    customLabels: [
      {
        defaultText: 'Your Cart',
        key: 'label.OCS_YOUR_CART',
        type: 'label',
        value: 'OCS_YOUR_CART',
      },
      {
        defaultText: 'ITEMS',
        key: 'label.OCS_ITEMS',
        type: 'label',
        value: 'OCS_ITEMS',
      },
      {
        defaultText: 'Edit',
        key: 'label.OCS_EDIT',
        type: 'label',
        value: 'OCS_EDIT',
      },
      {
        defaultText: 'Subtotal',
        key: 'label.OCS_SUBTOTAL',
        type: 'label',
        value: 'OCS_SUBTOTAL',
      },
      {
        defaultText: 'Check out',
        key: 'label.OCS_CHECKOUT',
        type: 'label',
        value: 'OCS_CHECKOUT',
      },
      {
        defaultText: 'Yes',
        key: 'label.OCS_YES',
        type: 'label',
        value: 'OCS_YES',
      },
      {
        defaultText: 'No',
        key: 'label.OCS_NO',
        type: 'label',
        value: 'OCS_NO',
      },
      {
        defaultText: 'Are you sure, you want to delete this item?',
        key: 'customMessage.OCS_DELETE_ITEM',
        type: 'customMessage',
        value: 'OCS_DELETE_ITEM',
      },
    ]
  },
  AddCardPage: {
    screenLabel: 'Add Card page ',
    customLabels: [
      {
        defaultText: 'Street Address',
        key: 'label.HL_STREET_ADDRESS',
        type: 'label',
        value: 'HL_STREET_ADDRESS',
      },
      {
        defaultText: 'Street Address',
        key: 'label.HL_STREET_ADDRESS_PLACEHOLDER',
        type: 'label',
        value: 'HL_STREET_ADDRESS_PLACEHOLDER',
      },
    ]
  }
};
