import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoginValidation } from '@reducers/store';

export const loginValidation = () => {
  const dispatch = useDispatch();
  const loginValidationData = useSelector(state => state.store.loginValidationData);
  const loading = useSelector(state => state.store.loading);
  const error = useSelector(state => state.store.error);

  useEffect(() => {
    if (!loginValidationData && !loading && !error) {
      dispatch(LoginValidation());
    }
  }, [dispatch, loginValidationData, loading, error]);
  return { loginValidationData, loading, error };
};
export default {
  loginValidation,
};
