
export const GET_ADMIN_USER_ROLE_TYPES_REQUESTED = 'adminUsers/GET_ADMIN_USER_ROLE_TYPES_REQUESTED';
export const GET_ADMIN_USER_ROLE_TYPES_SUCCESS = 'adminUsers/GET_ADMIN_USER_ROLE_TYPES_SUCCESS';
export const GET_ADMIN_USER_ROLE_TYPES_FAILURE = 'adminUsers/GET_ADMIN_USER_ROLE_TYPES_FAILURE';

export const GET_ADMIN_USERS_REQUESTED = 'adminUsers/GET_ADMIN_USERS_REQUESTED';
export const GET_ADMIN_USERS_SUCCESS = 'adminUsers/GET_ADMIN_USERS_SUCCESS';
export const GET_ADMIN_USERS_FAILURE = 'adminUsers/GET_ADMIN_USERS_FAILURE';

export const GET_ADMIN_USER_DETAIL_REQUESTED = 'adminUsers/GET_ADMIN_USER_DETAIL_REQUESTED';
export const GET_ADMIN_USER_DETAIL_SUCCESS = 'adminUsers/GET_ADMIN_USER_DETAIL_SUCCESS';
export const GET_ADMIN_USER_DETAIL_FAILURE = 'adminUsers/GET_ADMIN_USER_DETAIL_FAILURE';

export const ADD_ADMIN_USERS_REQUESTED = 'adminUsers/ADD_ADMIN_USERS_REQUESTED';
export const ADD_ADMIN_USERS_SUCCESS = 'adminUsers/ADD_ADMIN_USERS_SUCCESS';
export const ADD_ADMIN_USERS_FAILURE = 'adminUsers/ADD_ADMIN_USERS_FAILURE';

export const UPADTE_ADMIN_USER_REQUESTED = 'adminUsers/UPADTE_ADMIN_USER_REQUESTED';
export const UPADTE_ADMIN_USER_SUCCESS = 'adminUsers/UPADTE_ADMIN_USER_SUCCESS';
export const UPADTE_ADMIN_USER_FAILURE = 'adminUsers/UPADTE_ADMIN_USER_FAILURE';

export const DELETE_ADMIN_USER_REQUESTED = 'adminUsers/DELETE_ADMIN_USER_REQUESTED';
export const DELETE_ADMIN_USER_SUCCESS = 'adminUsers/DELETE_ADMIN_USER_SUCCESS';
export const DELETE_ADMIN_USER_FAILURE = 'adminUsers/DELETE_ADMIN_USER_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  adminUserRoles: [],
  adminUsersList: [],
  adminUserData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_USER_ROLE_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        adminUsersRoles: [],
      };
    }
    case GET_ADMIN_USER_ROLE_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        adminUserRoles: action.result,
      };
    }
    case GET_ADMIN_USER_ROLE_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ADMIN_USERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        adminUsersList: [],
      };
    }
    case GET_ADMIN_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        adminUsersList: action.result,
      };
    }
    case GET_ADMIN_USERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_ADMIN_USER_DETAIL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        adminUserData: [],
      };
    }
    case GET_ADMIN_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        adminUserData: action.result,
      };
    }
    case GET_ADMIN_USER_DETAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case ADD_ADMIN_USERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case ADD_ADMIN_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_ADMIN_USERS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case UPADTE_ADMIN_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case UPADTE_ADMIN_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPADTE_ADMIN_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_ADMIN_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case DELETE_ADMIN_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_ADMIN_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export const getAdminUsersRoleTypes = () => {
  return {
    types: [GET_ADMIN_USER_ROLE_TYPES_REQUESTED, GET_ADMIN_USER_ROLE_TYPES_SUCCESS, GET_ADMIN_USER_ROLE_TYPES_FAILURE],
    promise: client => client.get('getAdminUsersRoleTypes')
  };
};

export const getAdminUsers = () => {
  return {
    types: [GET_ADMIN_USERS_REQUESTED, GET_ADMIN_USERS_SUCCESS, GET_ADMIN_USERS_FAILURE],
    promise: client => client.get('getAdminUsers')
  };
};

export const adminUserDetail = (userId) => {
  return {
    types: [GET_ADMIN_USER_DETAIL_REQUESTED, GET_ADMIN_USER_DETAIL_SUCCESS, GET_ADMIN_USER_DETAIL_FAILURE],
    promise: client => client.get(`adminUserDetail/${userId}`)
  };
};

export const addAdminUsers = (data) => {
  return {
    types: [ADD_ADMIN_USERS_REQUESTED, ADD_ADMIN_USERS_SUCCESS, ADD_ADMIN_USERS_FAILURE],
    promise: client => client.post('createAdminUsers', { data })
  };
};

export const updateAdminUsers = (data) => {
  return {
    types: [UPADTE_ADMIN_USER_REQUESTED, UPADTE_ADMIN_USER_SUCCESS, UPADTE_ADMIN_USER_FAILURE],
    promise: client => client.post('updateAdminUsers', { data })
  };
};

export const deleteAdminUsers = (userId) => {
  return {
    types: [DELETE_ADMIN_USER_REQUESTED, DELETE_ADMIN_USER_SUCCESS, DELETE_ADMIN_USER_FAILURE],
    promise: client => client.post(`deleteAdminUsers/${userId}`)
  };
};
